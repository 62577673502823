import React, { Component } from 'react'
import { connect } from 'react-redux'
import Layout from '../layout/layout.jsx'
import Helmet from 'react-helmet'
import posed from 'react-pose'
import { easeFunction } from '../helper/variables.jsx'
import ScrollManager from '../helper/scrollManager.jsx'
import Parser from 'html-react-parser'
import InputField from '../components/common/inputfield.jsx'
import Textarea from '../components/common/textarea.jsx'
import CustomButton from '../components/button/button.jsx'
import Circle from '../components/graphic/circle'
import Sparks from '../components/graphic/sparks'
import Footer from '../components/common/footer'
import ExternalLink from '../helper/links/ExternalLink.jsx'

import './lets-talk.css'
import SectionIndicator from '../components/common/sectionIndicator.jsx'
import { navigate, graphql } from 'gatsby'
import { BrowserView } from 'react-device-detect'
import ReactGA from 'react-ga'

const PageTransition = posed.div({
  enter: {
    opacity: 1,
    transition: {
      ease: easeFunction(),
      duration: 1400,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      ease: easeFunction(),
      duration: 1400,
    },
  },
})

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

class LetsTalk extends Component {
  state = {
    mainNode: undefined,
    scrollValue: 0,
    momentumScrollValue: 0,
    name: '',
    phone: '',
    email: '',
    company: '',
    note: '',
    dummy: '',
    heroImageLoaded: false,
  }

  constructor(props) {
    super(props)

    this.mainNode = React.createRef()
  }

  showHeroImage = () => {
    this.setState({
      heroImageLoaded: true,
    })
  }

  componentDidMount() {
    ReactGA.initialize('UA-48349543-1')

    if (typeof window !== 'undefined')
      ReactGA.pageview(window.location.pathname + window.location.search)

    this.props.backgroundWhite()
    this.props.showMark()

    this.setState({
      mainNode: this.mainNode.current,
    })

    this.props.hideSymbol()
    this.props.hideScrollLabel()
    this.props.noHomepageLoading()
  }

  componentWillUnmount() {
    this.props.showScrollLabel()
  }

  updateMomentumScrollValue = value => {
    this.setState({
      momentumScrollValue: value,
    })
  }

  updateScrollValue = value => {
    this.setState({
      scrollValue: value,
    })
  }

  onSubmit = event => {
    event.preventDefault()

    const { name, phone, email, company, note, dummy } = this.state

    const fields = {
      name,
      phone,
      email,
      company,
      note,
    }

    fetch('https://formspree.io/xdlzwpzx', {
      method: 'post',
      mode: 'no-cors',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ ...fields }),
    }).then(() => {
      navigate('/thank-you')
    })
  }

  handleChange = event =>
    this.setState({ [event.target.name]: event.target.value })

  render() {
    const page = this.props.data.contentfulLetsTalk
    const data = this.props.data.contentfulDefaultSettings
    const { name, phone, email, company, note, heroImageLoaded } = this.state

    return (
      <Layout ref={this.mainNode}>
        <Helmet>
          <html lang="en" />
          <title>{page.metaData.metaTitle}</title>
          <meta name="description" content={page.metaData.description} />
          <meta name="keywords" content={page.metaData.keywords} />
          <meta property="og:title" content={page.metaData.metaTitle} />
          <meta property="og:description" content={page.metaData.description} />
          <meta property="og:type" content="website" />
          <meta
            property="og:image"
            content={
              page.metaData.ogImage
                ? `https:${page.metaData.ogImage.file.url}`
                : 'https://www.baunfire.com/ogimage.jpg'
            }
          />
          <meta
            property="og:url"
            content="https://www.baunfire.com/lets-talk"
          />
          <link rel="canonical" href="https://www.baunfire.com/lets-talk" />
          <script>
            {`  if (typeof fbq === 'undefined') {
              !function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', '588053714946302');
  fbq('track', 'PageView');
fbq('track', 'AddToCart');
  }else {
    fbq('track', 'PageView');
    fbq('track', 'AddToCart'); 
  }
`}
          </script>
        </Helmet>
        <PageTransition>
          <ScrollManager
            page={this.state.mainNode}
            scrollValue={this.state.scrollValue}
            updateScrollValue={this.updateScrollValue}
            updateMomentumScrollValue={this.updateMomentumScrollValue}
          >
            <div className="lets-talk">
              <div className="page-lets-talk">
                <div className="head-block-1">
                  <Circle />
                  <div className="detail-spark">
                    <Sparks type="3" />
                  </div>
                  <div className="head-info">
                    <h1 className="type-h1 headline">{page.headline}</h1>
                    <div className="subcopy">
                      {Parser(page.subCopy.childContentfulRichText.html)}
                    </div>
                    <form
                      name="inquiry"
                      action="https://formspree.io/xdlzwpzx"
                      method="POST"
                      className="inquiry-form"
                      onSubmit={this.onSubmit}
                    >
                      <input type="hidden" name="form-name" value="inquiry" />
                      <input type="hidden" name="bot-field" />
                      <div className="row">
                        <div className="full-column">
                          <label>
                            <InputField
                              type="text"
                              name="company"
                              value={company}
                              placeholder="Company name"
                              required
                              onChange={this.handleChange}
                            />
                          </label>
                        </div>
                      </div>
                      <div className="row">
                        <div className="full-column">
                          <label>
                            <InputField
                              type="text"
                              name="name"
                              placeholder="Your name"
                              value={name}
                              required
                              onChange={this.handleChange}
                            />
                          </label>
                        </div>
                      </div>
                      <div className="row">
                        <div className="full-column">
                          <label>
                            <InputField
                              type="tel"
                              name="phone"
                              value={phone}
                              placeholder="Phone number"
                              required
                              onChange={this.handleChange}
                            />
                          </label>
                        </div>
                      </div>
                      <div className="row">
                        <div className="full-column">
                          <label>
                            <InputField
                              type="email"
                              name="email"
                              value={email}
                              placeholder="Email address"
                              required
                              onChange={this.handleChange}
                            />
                          </label>
                        </div>
                      </div>

                      <div className="row">
                        <div className="full-column">
                          <label>
                            <Textarea
                              name="note"
                              value={note}
                              placeholder="Tell us about your project (Scope, timeline, budget, etc.)"
                              required
                              onChange={this.handleChange}
                            />
                          </label>
                        </div>
                      </div>
                      <div className="row button-container">
                        <button
                          className={`form-button type-comp2 theme-black animate`}
                          type="submit"
                        >
                          <div className="button-background">
                            <div className="left" />
                            <div className="center" />
                            <div className="right" />
                          </div>
                          <div className="text">Submit</div>
                          <div className="plus-icon" />
                        </button>
                      </div>
                    </form>
                  </div>
                  <div className="head-image-container">
                    <div
                      className={`head-image-wrapper ${heroImageLoaded &&
                        'appear'}`}
                    >
                      <div
                        className={`head-image ${this.props.doCover &&
                          'cover'}`}
                        style={{
                          backgroundImage: `url(${page.keyImage.file.url})`,
                        }}
                      />
                      <img
                        src={page.keyImage.file.url}
                        alt=""
                        onLoad={this.showHeroImage}
                        style={{
                          display: 'none',
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <section className="additional-info">
                <div className="info-wrapper content-container">
                  <div className="column">
                    <div className="upper">
                      <h3 className="type-h6 column-title">
                        {page.projectTitle}
                      </h3>
                      <div className="description">
                        {page.projectDescription}
                      </div>
                    </div>
                    <div className="lower">
                      <ExternalLink
                        href={`mailto:${page.projectEmail}`}
                        className="type-sub3 color-red link-underline email"
                      >
                        {page.projectEmail}
                      </ExternalLink>
                    </div>
                  </div>

                  <div className="column">
                    <div className="upper">
                      <h3 className="type-h6 column-title">
                        {page.helloTitle}
                      </h3>
                      <div className="description">{page.helloDescription}</div>
                    </div>
                    <div className="lower">
                      <ExternalLink
                        href={`mailto:${page.helloEmail}`}
                        className="type-sub3 color-red link-underline email"
                      >
                        {page.helloEmail}
                      </ExternalLink>
                    </div>
                  </div>

                  <div className="column address">
                    <div className="upper">
                      <h3 className="type-h6 column-title">
                        {page.officeTitle}
                      </h3>
                      <div className="description">{data.address}</div>
                    </div>
                    <div className="lower">
                      <ExternalLink
                        href={`tel:${data.phone}`}
                        className="type-sub3 color-red link-underline tel"
                      >
                        {data.phone}
                      </ExternalLink>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <section ref={this.sectionFooter}>
              <Footer />
            </section>
          </ScrollManager>
          <BrowserView>
            <SectionIndicator>Let's talk</SectionIndicator>
          </BrowserView>
        </PageTransition>
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{ __html: `llfrmid=31567` }}
        />
        <script
          type="text/javascript"
          src="https://formalyzer.com/formalyze_init.js"
        />
        <script
          type="text/javascript"
          src="https://formalyzer.com/formalyze_call_secure.js"
        />
      </Layout>
    )
  }
}

export const query = graphql`
  query($page: String = "Let's talk") {
    contentfulLetsTalk(title: { eq: $page }) {
      metaData {
        title
        metaTitle
        description
        keywords
      }
      headline
      keyImage {
        file {
          url
        }
      }
      subCopy {
        childContentfulRichText {
          html
        }
      }
      projectTitle
      projectDescription
      projectEmail
      helloTitle
      helloDescription
      helloEmail
      officeTitle
    }
    contentfulDefaultSettings(title: { eq: "Settings" }) {
      phone
      address
    }
  }
`

const mapStateToProps = state => {
  return {
    theme: state.backgroundColor,
    loaded: state.loaded,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    backgroundBlack: () => dispatch({ type: 'BACKGROUND_BLACK' }),
    backgroundWhite: () => dispatch({ type: 'BACKGROUND_WHITE' }),
    backgroundRed: () => dispatch({ type: 'BACKGROUND_RED' }),
    noHomepageLoading: () => dispatch({ type: 'NO_HOMEPAGE_LOADING' }),
    showScrollLabel: () => dispatch({ type: 'SHOW_SCROLL_LABEL' }),
    hideScrollLabel: () => dispatch({ type: 'HIDE_SCROLL_LABEL' }),
    hideSymbol: () => dispatch({ type: 'SHOW_LOGO' }),
    showMark: () => dispatch({ type: 'SHOW_MARK' }),
    flagLoad: () => dispatch({ type: 'LOADED' }),
  }
}

const ConnectedLetsTalk = connect(
  mapStateToProps,
  mapDispatchToProps
)(LetsTalk)

export default ConnectedLetsTalk
